import React from "react"
import { graphql } from "gatsby"
// import SEO from "../components/SEO"
import Layout from '../layouts/Layout'
import SubpageHeader from '../components/SubpageHeader'
import Lightbox from '../components/Lightbox'
import LinkBack from '../components/LinkBack'

export const query = graphql`
    query EventQuery($uid: String) {
        prismic {
            allEvents(uid: $uid) {
                edges {
                    node {
                        event_title
                        event_description
                        photos {
                            photo
                            photoSharp {
                                childImageSharp {
                                    fluid(maxWidth: 760, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
            allAbouts {
                edges {
                    node {
                    short_footer_text
                    }
                }
            }
        }
    }
`

const Event = ({ data, location }) => {
    const footerData = data.prismic.allAbouts.edges.slice(0, 1).pop()
    const eventData = data.prismic.allEvents.edges.slice(0, 1).pop()
    if (!eventData || !footerData) return null

    return (
        <>
            {/* <SEO title="Events" /> */}
            <Layout
                location={location}
                footerShortText={footerData.node.short_footer_text[0].text}
                footerLinksArray={[
                    {
                        name: 'Diary',
                        URL: '/diary/'
                    },
                    {
                        name: 'Home',
                        URL: '/'
                    },
                    {
                        name: 'Projects',
                        URL: '/projects/'
                    }
                ]}>
                <SubpageHeader headline={eventData.node.event_title[0].text} introTextArray={eventData.node.event_description}></SubpageHeader>
                <Lightbox 
                    imageArray={eventData.node.photos}
                    galleryColumns={2}
                ></Lightbox>
                <LinkBack linkURL={"/events/"} linkName={"All Events"}></LinkBack>
            </Layout>
        </>
    )
}

export default Event
